import React from 'react';
import { useParams } from 'react-router-dom';
import Products from './Products';

const ProductsWrapper = () => {
  const { owner } = useParams();
  if (['tmcode', 'tpawlak', 'jmrohen', 'drjabluszko', 'remix', 'dmowskiego'].includes(owner)) {
    return <Products owner={owner} />;
  }
  return null
};

export default ProductsWrapper;